import { Header } from '@/Components/Header';
import { TButton } from '@/Components/TButton';
import { Col, Form, InputGroup, ListGroup, Row } from 'react-bootstrap';
import {
    Bolder,
    CapitalizedRow,
    DashedHR,
    EventAddForm,
    ImagePreview,
    Menu,
    SubmitButton,
    Title,
    WarnText,
    RowContainer,
    EventBackground,
    FormContainer,
    MenuItem,
    Subtitle,
    TicketRow,
    AddTicketButton,
    AddButton,
    TicketForm,
    DeleteButton
} from './styled';

import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
    CreateEventRequest,
    EventResponse,
    IParticipantSearchBy,
} from '@/Services/api/models/eventModel';
import { useEffect, useRef, useState } from 'react';
import ApiService from '@/Services/api/ApiService';

import { SubmitHandler, Controller } from 'react-hook-form';
import ModalLoading from '@/Components/ModalLoading';

import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { toBase64 } from '@/util/base64';
import { ParticipantFormValues } from '@/Pages/Participants/Adicionar';
import { useToken } from '@/Components/UseToken';
import { AddOutlined, AirplaneTicket, ConfirmationNumber, Edit, Person, PlusOneOutlined, Settings, Tv } from '@mui/icons-material';
import { Link, LinkRequest, RsvpEventRequest, RsvpEventResponse } from '@/Services/api/models/rsvpModels';
import RsvpService from '@/Services/rsvp/RsvpService';
import { readExcel } from '@/util/SaveFromExcel';

interface EventFormValues {
    description: string;
    place: string;
    initialDate: string;
    finalDate: string;
    serviceOrder: string;
    backgroundImage: any;
    canDuplicateDoc: boolean;
    canRegisterParticipant: boolean;
    participantSearchBy: keyof typeof IParticipantSearchBy;
    active: boolean;
    requiredFields: string[];
    removeTitle: boolean;
    docType: string;
    checkinType: boolean;
    useVirtualKeyboard: boolean;
    cek: boolean;
    cekQuantity: number;
    malex: boolean;
    showCheckinButton: boolean;
    attraction: boolean;
    labelPrintCount: number;
    enquetes: boolean;
    questions: string[];
    additionalCategories: string;
    accessControl: boolean;
    blinket_integration: boolean;
    sympla_integration: boolean;
    hotmart_integration: boolean;
    rsvp_auto_send_email: boolean;
    rsvp_terms: string;
    rsvp_blinket_id: string;
    rsvp_sympla_id: string;
    rsvp_sympla_token: string;
    rsvp_hotmart_id: string;
    rsvp_hotmart_client_id: string;
    rsvp_hotmart_client_secret: string;
    rsvp_hotmart_basic_auth: string;
    welcomeMessage: string;
    eGuide: boolean;
    qrCodeUrl: string;
    buttonColor: string;
    facialRecognition: boolean;
}

interface LinkFormValues {
    name: string;
    availability: number;
    primary_color: string;
    email_template: string;
    price: number;
    cancel_option: string;
    allow_any: boolean;
    installments: number;
    random_code: boolean;
    participant_type: string;
    participant_file: boolean;
    additional_info: string;
    show_terms: boolean;
    background_image: File;
    background_mobile_image: File; 
    free: boolean;
    allowed_participants: string[];
    link_facial_recognition: boolean;
}

export function AddEvent() {
    const location = useLocation();
    const navigate = useNavigate();

    const [eventToEdit, setEventToEdit] = useState<EventResponse>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [links, setLinks] = useState<Link[]>([])
    const [linkToEdit, setLinkToEdit] = useState<Link>()
    const [rsvpEvent, setRsvpEvent] = useState<RsvpEventResponse>()
    const [linkFormOpen, setLinkFormOpen] = useState<boolean>(false)

    const [imgPreview, setImgPreview] = useState('');
    const { token, setToken } = useToken()
    const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<string>('geral')
    const contentRef = useRef(null);
    const menuItems = [
        {
            key: 'geral',
            name: 'Geral',
            icon: <Settings />
        },
        {
            key: 'participantes',
            name: 'Participantes',
            icon: <Person />
        },
        {
            key: 'ingressos',
            name: 'Ingressos',
            icon: <ConfirmationNumber />
        },
        {
            key: 'autoatendimento',
            name: 'Autoatendimento',
            icon: <Tv />
        }       
    ]
    

    if(isUserAdmin) {
        menuItems.push({
            key: 'módulos',
            name: 'Módulos',
            icon: <AddOutlined />
        })
    }

    const participantFields: Omit<ParticipantFormValues, 'ativo'> = {
        nome: '',
        sobrenome: '',
        documento: '',
        cargo: '',
        cidade: '',
        email: '',
        empresa: '',
        estado: '',
        telCelular: '',
        telComercial: '',
        categoria: 0,
        obs: '',
        additionalCategory: ''
    };

    const schema = yup
        .object({
            description: yup.string().required('O campo Nome é obrigatório'),
            place: yup.string().required('O campo Local é obrigatório'),
            initialDate: yup
                .date()
                .required('O campo Data Inicial é obrigatório')
                .nullable()
                .default(undefined),
            finalDate: yup.date().required('O campo Data Final é obrigatório'),
            serviceOrder: yup.string().required('O campo O.S é obrigatório'),
            canDuplicateDoc: yup.mixed(),
            canRegisterParticipant: yup.mixed(),
            participantSearchBy: yup
                .mixed<IParticipantSearchBy>()
                .required('O campo Tipo de pesquisa é obrigatório'),
            active: yup.boolean(),
            removeTitle: yup.boolean(),
            requiredFields: yup.array(yup.string()),
            docType: yup.string().required('O campo Tipo de documento é obrigatório'),
            checkinType: yup.boolean(),
            useVirtualKeyboard: yup.boolean(),
            cek: yup.boolean(),
            cekQuantity: yup.number().min(0).integer(),
            malex: yup.boolean(),
            showCheckinButton: yup.boolean(),
            enquetes: yup.boolean(),
            additionalCategories: yup.string(),
            accessControl: yup.boolean(),
            blinket_integration: yup.boolean(),
            sympla_integration: yup.boolean(),
            rsvp_auto_send_email: yup.boolean(),
            rsvp_terms: yup.string(),
            rsvp_blinket_id: yup.string().nullable(),
            rsvp_sympla_id: yup.string().nullable(),
            rsvp_sympla_token: yup.string().nullable(),
            rsvp_hotmart_id: yup.string().nullable(),
            rsvp_hotmart_client_id: yup.string().nullable(),
            rsvp_hotmart_client_secret: yup.string().nullable(),
            rsvp_hotmart_basic_auth: yup.string().nullable(),
            welcomeMessage: yup.string().nullable(),
            eGuide: yup.boolean(),
            qrCodeUrl: yup.string().nullable()
            
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch,
        getValues,
    } = useForm<EventFormValues>({
        resolver: yupResolver(schema),
    });

    const linkSchema = yup
        .object({
            name: yup.string().required('O nome do link é obrigatório'),
            availability: yup.number().min(0, 'A quantidade mínima é 0'),
            primary_color: yup.string().required(),
            email_template: yup.string(),
            price: yup.number().positive(),
            cancel_option: yup.string().required(),
            allow_any: yup.boolean(),
            installments: yup.number().positive().min(1),
            random_code: yup.boolean(),
            participant_type: yup.string(),
            participant_file: yup.boolean(),
            additional_info: yup.string(),
            show_terms: yup.boolean()
        })
        .required();

    const {
        register: linkRegister,
        handleSubmit: linkHandleSubmit,
        formState: { errors: linkErrors },
        setValue: linkSetValue,
        control: linkControl,
        watch: linkWatch,
        getValues: linkGetValues,
        reset: linkReset
    } = useForm<LinkFormValues>({
        resolver: yupResolver(linkSchema),
    });

    useEffect(() => {
        handleGetUserByID()
        if (location.state) {
            handleGetEventByID(+location.state);
        }
    }, []);

    useEffect(() => {
        if (eventToEdit) {
            getEventLinks()

            setValue('description', eventToEdit.description);
            setValue('serviceOrder', eventToEdit.serviceOrder);
            setValue('place', eventToEdit.place);
            setValue('active', !eventToEdit.active);
            setValue('participantSearchBy', eventToEdit.participantSearchBy);
            setValue('canDuplicateDoc', eventToEdit.allowDuplicateDocumentNumber);
            setValue('finalDate', `${eventToEdit.finalDate}T00:00:00`);
            setValue('initialDate', `${eventToEdit.initialDate}T00:00:00`);
            setValue('requiredFields', eventToEdit.requiredFields?.split(','));
            setValue('canRegisterParticipant', eventToEdit.allowParticipantAutoRegistration);
            setValue('docType', eventToEdit.details);
            setValue('checkinType', eventToEdit.checkinType === 'CONFIRMATION_REQUIRED');
            setValue('useVirtualKeyboard', eventToEdit.useVirtualKeyboard);
            setImgPreview(eventToEdit.backgroundImage ?? '');
            setValue('cek', eventToEdit.kitQuantityLimit? true : false);
            setValue('cekQuantity', eventToEdit.kitQuantityLimit);
            setValue('malex',eventToEdit.malexControl);
            setValue('showCheckinButton',eventToEdit.showCheckinButton);
            setValue('attraction',eventToEdit?.attractionControl);
            setValue('labelPrintCount', eventToEdit?.labelPrintCount ?? 1);
            setValue('enquetes', eventToEdit?.surveyControl);
            setValue('additionalCategories', eventToEdit?.additionalCategories);
            setValue('accessControl', eventToEdit?.accessControl);
            setValue('blinket_integration', eventToEdit?.blinketIntegration)
            setValue('sympla_integration', eventToEdit?.symplaIntegration)
            setValue('hotmart_integration', eventToEdit?.hotmartIntegration)
            setValue('welcomeMessage', eventToEdit?.welcomeMessage)
            setValue('eGuide', eventToEdit?.qrCodeUrl? true : false)
            setValue('qrCodeUrl', eventToEdit?.qrCodeUrl)
            setValue('buttonColor', eventToEdit?.buttonColor)
            setValue('facialRecognition', eventToEdit?.facialRecognition)
        }
    }, [eventToEdit]);

    const getEventLinks = async () => {
        const response = await RsvpService.getLinksByRentpassEvent(eventToEdit?.id)
        setLinks(response)
    }

    const handleGetUserByID = async () => {
        try {
            setIsLoading(true);
            const response = await ApiService.getUserByID(token?.user?.id);
            if(response.userGroup === 'ADMIN') {
                setIsUserAdmin(true)
            }
        } catch (error: any) {
            toast.error(error?.message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleGetEventByID = async (id: number) => {
        try {
            setIsLoading(true);
            const response = await ApiService.getEventById(id);
            response.backgroundImage = await ApiService.getBackgroundImage(id).catch(() => {
                return '';
            });
            setEventToEdit(response);
        } catch (error: any) {
            toast.error(error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetRsvpEvent = async () => {
        try {
            const response = await RsvpService.getEventByRentpassId(eventToEdit?.id)
            setRsvpEvent(response)
            setValue('rsvp_sympla_id', response?.sympla_id)
            setValue('rsvp_sympla_token', response?.sympla_token)
            setValue('rsvp_blinket_id', response?.blinket_id)
            setValue('rsvp_hotmart_id', response?.hotmart_id)
            setValue('rsvp_hotmart_client_id', response?.hotmart_client_id)
            setValue('rsvp_hotmart_client_secret', response?.hotmart_client_secret)
            setValue('rsvp_hotmart_basic_auth', response?.hotmart_basic_auth)

        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleSubmitForm: SubmitHandler<EventFormValues> = async (data) => {
        try {
            setIsLoading(true);
            const createEventRequest: CreateEventRequest = {
                active: !data.active,
                description: data.description,
                serviceOrder: data.serviceOrder,
                place: data.place,
                allowDuplicateDocumentNumber: data.canDuplicateDoc,
                allowParticipantAutoRegistration: data.canRegisterParticipant,
                participantSearchBy: data.participantSearchBy,
                initialDate: data.initialDate,
                requiredFields: data.requiredFields?.join(',') ?? '',
                finalDate: data.finalDate,
                details: data.docType,
                removeTitleLabel: data.removeTitle,
                checkinType: data.checkinType? 'CONFIRMATION_REQUIRED' : 'CONFIRMATION_AUTO',
                useVirtualKeyboard: data.useVirtualKeyboard,
                malexControl: data.malex,
                showCheckinButton: data.showCheckinButton,
                attractionControl: data.attraction,
                labelPrintCount: data.labelPrintCount ?? 1,
                surveyControl: data.enquetes,
                additionalCategories: data.additionalCategories ?? null,
                accessControl: data.accessControl,
                blinketIntegration: data.blinket_integration,
                symplaIntegration: data.sympla_integration,
                hotmartIntegration: data.hotmart_integration,
                welcomeMessage: data.welcomeMessage,
                qrCodeUrl: data.qrCodeUrl,
                buttonColor: data.buttonColor,
                facialRecognition: data.facialRecognition
            }
            if (!data.cek) {
                createEventRequest.kitQuantityLimit = 0;
            } else {
                createEventRequest.kitQuantityLimit = data.cekQuantity || 0;
            }
            if (data.backgroundImage.length >= 1) {
                createEventRequest.backgroundImage = await toBase64(data.backgroundImage[0]).catch(() => { throw new Error('Erro ao converter a imagem') })
            } else {
                createEventRequest.backgroundImage = eventToEdit?.backgroundImage
            }

            if (!!eventToEdit?.id) {
                createEventRequest.id = eventToEdit.id
                createEventRequest.labelResolution = eventToEdit.labelResolution
                createEventRequest.labelConfiguration = eventToEdit.labelConfiguration
                createEventRequest.printerModel = eventToEdit.printerModel
            }
            const event = await ApiService.upInsertEvent(createEventRequest)
            await ApiService.updateImageCache()


            const createRsvpEventRequest: RsvpEventRequest = {
                update: eventToEdit? true : false,
                token: null,
                user_id: null,
                rentpass_id: event?.id,
                required_fields: data.requiredFields?.join(',') ?? '',
                auto_send_email: data?.rsvp_auto_send_email,
                name: data?.description,
                date: data?.initialDate !== data?.finalDate? `De ${new Date(data?.initialDate)?.toLocaleDateString()} à ${new Date(data?.finalDate)?.toLocaleDateString()}` : `${new Date(data?.initialDate)?.toLocaleDateString()}`,
                owner: null,
                place: data?.place,
                terms: data?.rsvp_terms,
                blinket_id: data?.rsvp_blinket_id,
                sympla_id: data?.rsvp_sympla_id,
                sympla_token: data?.rsvp_sympla_token,
                hotmart_id: data?.rsvp_hotmart_id,
                hotmart_client_id: data?.rsvp_hotmart_client_id,
                hotmart_client_secret: data?.rsvp_hotmart_client_secret,
                hotmart_basic_auth: data?.rsvp_hotmart_basic_auth
            }

            const rsvpEvent = await RsvpService.createEvent(createRsvpEventRequest)

            if(data?.sympla_integration) {
                await RsvpService.enableSymplaIntegration({
                    token: null,
                    user_id: null,
                    event_id: event?.id
                })
            }
            else {
                await RsvpService.disableSymplaIntegration({
                    token: null,
                    user_id: null,
                    event_id: event?.id
                })
            }

            if(data?.blinket_integration) {
                await RsvpService.enableBlinketIntegration({
                    token: null,
                    user_id: null,
                    event_id: event?.id
                })
            }
            else {
                await RsvpService.disableBlinketIntegration({
                    token: null,
                    user_id: null,
                    event_id: event?.id
                })
            }

            if(data?.hotmart_integration) {
                await RsvpService.enableHotmartIntegration({
                    token: null,
                    user_id: null,
                    event_id: event?.id
                })
            }
            else {
                await RsvpService.disableHotmartIntegration({
                    token: null,
                    user_id: null,
                    event_id: event?.id
                })
            }

            if(!event?.active) {
                await RsvpService.disableSymplaIntegration({
                    token: null,
                    user_id: null,
                    event_id: event?.id
                })
                await RsvpService.disableHotmartIntegration({
                    token: null,
                    user_id: null,
                    event_id: event?.id
                })
            }

            if (links?.length) {
                for (const link of links) {
                    console.log(link)
                    const linkRequest:LinkRequest = {
                        token: null,
                        user_id: null,
                        id: link?.id ?? null,
                        name: link?.name,
                        availability: link?.availability,
                        background_image: typeof(link?.background_image) === 'string' ? null : link?.background_image,
                        background_mobile_image: typeof(link?.background_mobile_image) === 'string' ? null : link?.background_mobile_image,
                        primary_color: link?.primary_color,
                        email_template: link?.email_template,
                        price: link?.price,
                        cancel_option: link?.cancel_option,
                        allow_any: link?.allow_any,
                        installments: link?.installments,
                        i_rate: link?.i_rate,
                        random_code: link?.random_code,
                        participant_type: link?.participant_type,
                        participant_file: link?.participant_file,
                        additional_info: link?.additional_info,
                        show_terms: link?.show_terms,
                        event: rsvpEvent?.id,
                        facial_recognition: link?.facial_recognition
                    }
                    if(!link?.allow_any) {
                        linkRequest.allowed_participants = link?.allowed_participants
                    }
                    console.log(linkRequest)
                    await RsvpService.createLink(linkRequest)
                }
            }

            toast.success('Informações alteradas com sucesso')
            navigate('/eventos/consultar')
        } catch (e: any) {
            toast.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleScroll = () => {
        const content = contentRef.current;
        const sectionElements = menuItems.map(item => document.getElementById(item.key));
        sectionElements.forEach(section => {
          const sectionTop = section.offsetTop - content.offsetTop;
          const sectionHeight = section.clientHeight;
          if (content.scrollTop >= sectionTop && content.scrollTop < sectionTop + sectionHeight) {
            setSelectedItem(section.id);
          }
        });
    };

    const handleImportAllowedParticipants = async (file: File | string) => {
        const data:any[] = await readExcel(file as File)
        const cpf_array: string[] = []
        if(data) {
            data.map((row) => {
                const cpf_key:string = Object.keys(row).find((key) => key.toLowerCase().includes('cpf')) ?? ''
                cpf_array.push(row[cpf_key])
            })
            console.log(cpf_array)
            linkSetValue('allowed_participants', cpf_array)
        }
    }

    const handleSelectItem = (item: string) => {
        setSelectedItem(item)
        document.getElementById(item)?.scrollIntoView({ behavior: 'auto', block: 'start' });
    }

    const handleAddLink = () => {
        const data = linkGetValues()

        if(Number(data.price !== 0) && !data.installments) {
            return
        }

        const linkRequest:Link = {
            id: linkToEdit? linkToEdit.id : null,
            name: data?.name,
            availability: data?.availability,
            background_image: data?.background_image?.[0],
            background_mobile_image: data?.background_mobile_image?.[0],
            primary_color: data?.primary_color,
            email_template: data?.email_template,
            price: data?.price?.toString(),
            cancel_option: data?.cancel_option,
            allow_any: !Boolean(data?.allow_any),
            installments: data?.installments,
            i_rate: null,
            random_code: Boolean(data?.random_code),
            participant_type: data?.participant_type,
            participant_file: Boolean(data?.participant_file),
            additional_info: data?.additional_info,
            show_terms: Boolean(data?.show_terms),
            rentpass_event: null,
            allowed_participants: data?.allowed_participants,
            facial_recognition: data?.link_facial_recognition
        }
        console.log(linkRequest)
        if(linkToEdit) {
            const current = links
            const linkIndex = current?.indexOf(current?.find((l) => l.name === data?.name))
            current.splice(linkIndex,1)
            current.push(linkRequest)
            setLinks(current)
        }
        else {
            setLinks(links => [...links, linkRequest])
        }
        setLinkToEdit(undefined)
        setLinkFormOpen(false)
    }

    /*const handleDeleteEvent = async () => {
        setDeleteModal(true)
    }

    const deleteEvent = async () => {
        setDeleteModal(false)
        setIsLoading(true)
        try {
            const response = await ApiService.deleteEvent(eventToEdit?.id)
        } catch(error:any) {
            toast.error(error)
        } finally {
            setIsLoading(false)
            navigate('/eventos/consultar')
        }
    }*/

    const usePreventWheel = () => {
        useEffect(() => {
          const handleWheel = (event) => {
            if (event.target.type === 'number') {
              event.preventDefault();
            }
          };
      
          document.addEventListener('wheel', handleWheel, { passive: false });
      
          return () => {
            document.removeEventListener('wheel', handleWheel);
          };
        }, []);
    };
    usePreventWheel()  

    useEffect(() => {
        const content = contentRef.current;
        content?.addEventListener('scroll', handleScroll);
        return () => content.removeEventListener('scroll', handleScroll);
    }, []);



    useEffect(() => {
        if(linkWatch('free')) {
            linkSetValue('price', 0)
            linkSetValue('installments', null)
        }
        else {
            linkSetValue('random_code', false)
        }
    }, [linkWatch('free')])
   
    useEffect(() => {
        if(linkFormOpen && !linkToEdit) {
            linkSetValue('free', true)
        }
    },[linkFormOpen])

    useEffect(() => {
        if(linkToEdit) {
            setLinkFormOpen(true)
            linkSetValue('name', linkToEdit?.name)
            linkSetValue('availability', linkToEdit?.availability)
            linkSetValue('primary_color', linkToEdit?.primary_color)
            linkSetValue('email_template', linkToEdit?.email_template)
            linkSetValue('price', Number(linkToEdit?.price))
            linkSetValue('cancel_option', linkToEdit?.cancel_option)
            linkSetValue('allow_any', !linkToEdit?.allow_any)
            linkSetValue('installments', linkToEdit?.installments)
            linkSetValue('random_code', linkToEdit?.random_code)
            linkSetValue('participant_type', linkToEdit?.participant_type)
            linkSetValue('participant_file', linkToEdit?.participant_file)
            linkSetValue('additional_info', linkToEdit?.additional_info)
            linkSetValue('show_terms', linkToEdit?.show_terms)
            if(Number(linkToEdit?.price) === 0) {
                linkSetValue('free', true)
            }

        }
    },[linkToEdit])

    useEffect(() => {
        if(eventToEdit) {
            handleGetRsvpEvent()
        }
    },[eventToEdit])

    useEffect(() => {
        linkSetValue('link_facial_recognition', watch('facialRecognition'))
    },[watch('facialRecognition'), links])

    return (
        <EventBackground>
            {/*<ConfirmDeleteModal show={deleteModal} onClose={() => setDeleteModal(false)} onConfirm={() => deleteEvent()} />*/}
            <ModalLoading isActive={isLoading} />
            <Header pageTitle='Edição de Evento' />
            <RowContainer>
            <Menu>
                {menuItems?.map((item) =>
                    <MenuItem className={selectedItem === item.key && 'selected'} onClick={() => handleSelectItem(item.key)}>
                        {item.icon}
                        {item.name}
                    </MenuItem>
                )}
            </Menu>
            <FormContainer ref={contentRef}>
                <EventAddForm id='form'>
                    <Form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Title id='geral'>Geral</Title>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                *Nome
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('description')} type='text' />
                                {errors.description?.message && (
                                    <WarnText> {errors.description?.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                *Local
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('place')} type='text' />
                                {errors.place?.message && (
                                    <WarnText> {errors.place?.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                *Dt.Inicio
                            </Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Text id='basic-addon1'>
                                        {' '}
                                        <FaCalendarAlt size={10} />{' '}
                                    </InputGroup.Text>
                                    <Col>
                                        <Controller
                                            control={control}
                                            name='initialDate'
                                            render={({ field }) => (
                                                <DatePicker
                                                    className='form-control'
                                                    placeholderText='Select date'
                                                    onChange={(date) => field.onChange(date?.toString() || '')}
                                                    selected={
                                                        field.value
                                                            ? new Date(field.value)
                                                            : undefined
                                                    }
                                                    dateFormat={'dd/MM/yyyy'}
                                                />
                                            )}
                                        />
                                    </Col>
                                </InputGroup>
                                {errors.initialDate?.message && (
                                    <WarnText> {errors.initialDate?.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                *Dt.Final
                            </Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Text id='basic-addon1'>
                                        {' '}
                                        <FaCalendarAlt size={10} />{' '}
                                    </InputGroup.Text>
                                    <Col>
                                        <Controller
                                            control={control}
                                            name='finalDate'
                                            render={({ field }) => (
                                                <DatePicker
                                                    placeholderText='Select date'
                                                    className='form-control'
                                                    onChange={(date) => field.onChange(date?.toString() || '')}
                                                    selected={
                                                        field.value
                                                            ? new Date(field.value)
                                                            : undefined
                                                    }
                                                    dateFormat={'dd/MM/yyyy'}
                                                />
                                            )}
                                        />
                                    </Col>
                                </InputGroup>
                                {errors.finalDate?.message && (
                                    <WarnText> {errors.finalDate.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-2'>
                            <Form.Label column sm={2}>
                                *Nº OS
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control type='text' {...register('serviceOrder')} />
                                {errors.serviceOrder?.message && (
                                    <WarnText> {errors.serviceOrder.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Check
                                    type={'checkbox'}
                                    id={`canDuplicateDoc`}
                                    label={`Permite duplicar documento`}
                                    {...register('canDuplicateDoc')}
                                />
                                {errors.canDuplicateDoc?.message && (
                                    <WarnText> {errors.canDuplicateDoc.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>


                        <DashedHR />
                        <Title id='participantes'>Participantes</Title>
                        <Subtitle>Campos Obrigatórios</Subtitle>
                        <Form.Group as={Row} className='mb-3'>
                            <Col sm={{ span: 5, offset: 2 }} className={'checkboxSection'}>
                                {Object.keys(participantFields).map((field) => {
                                    if (!field) return;
                                    const participantSearchByValue = watch('participantSearchBy') || [];
                                    const checkboxValue = watch('requiredFields') || [];
                                    const pairs = {documento: 'DOCUMENT_NUMBER', nome: 'NAME', email: 'EMAIL', telCelular: 'PHONE_NUMBER'}
                                    return (
                                        <Form.Check
                                            key={field}
                                            {...register('requiredFields')}
                                            value={field}
                                            type={'checkbox'}
                                            id={`${field}-${'checkbox'}`}
                                            label={
                                                <CapitalizedRow>
                                                    {' '}
                                                    {field} Obrigatório{' '}
                                                </CapitalizedRow>
                                            }
                                            checked={participantSearchByValue.includes(pairs[field]) || checkboxValue.includes(field)}
                                            onChange={(e) => {
                                                const checkedValue = e.target.checked
                                                  ? [...checkboxValue, field]
                                                  : checkboxValue.filter((item) => item !== field);
                                                setValue('requiredFields', checkedValue);
                                              }}
                                        />
                                    );
                                })}
                            </Col>
                        </Form.Group>
                        <Subtitle id='participantes'>Categorias de Participante</Subtitle>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column>Categorias (separadas por vírgula)</Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('additionalCategories')} type='text' />
                                {errors.docType?.message && (
                                    <WarnText> {errors.additionalCategories.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Subtitle>Pesquisa de Participante</Subtitle>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column>*Tipo de pesquisa</Form.Label>
                            <Col sm={10}>
                                {(
                                    Object.keys(
                                        IParticipantSearchBy,
                                    ) as (keyof typeof IParticipantSearchBy)[]
                                ).map((searchByMapped) => {
                                    const pairs = {documento: 'DOCUMENT_NUMBER', nome: 'NAME', email: 'EMAIL', telCelular: 'PHONE_NUMBER'}
                                    const requiredFields = getValues('requiredFields')
                                    return (
                                        <Form.Check
                                            key={searchByMapped}
                                            {...register('participantSearchBy')}
                                            inline
                                            label={
                                                <CapitalizedRow>
                                                    {' '}
                                                    {IParticipantSearchBy[
                                                        searchByMapped
                                                    ].toLowerCase()}{' '}
                                                </CapitalizedRow>
                                            }
                                            value={searchByMapped}
                                            type={'radio'}
                                            id={`Name-${'radio'}-1`}
                                            onChange={(e) => {
                                                requiredFields.push(Object.keys(pairs).find(key => pairs[key] === e.target.value))
                                                setValue('requiredFields',requiredFields)
                                            }}
                                        />
                                    );
                                })}
                                {errors.participantSearchBy?.message && (
                                    <WarnText> {errors.participantSearchBy.message} </WarnText>
                                )}
                            </Col>
                            <Form.Label column>* Tipo de documento</Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('docType')} type='text' />
                                {errors.docType?.message && (
                                    <WarnText> {errors.docType.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Check
                                    type={'checkbox'}
                                    id={`facialRecognition`}
                                    label={`Reconhecimento Facial`}
                                    {...register('facialRecognition')}
                                />
                                {errors.facialRecognition?.message && (
                                    <WarnText> {errors.facialRecognition.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>


                        <DashedHR />
                        <Title id='ingressos'>Ingressos</Title>
                        <Form.Group as={Row} className='mb-1 d-flex justify-content-center'>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column>Integração Eduzz</Form.Label>
                                <Col sm={10}>
                                    <Form.Check
                                        key={'blinket_integration'}
                                        {...register('blinket_integration')}
                                        label={
                                            <CapitalizedRow>
                                                Habilitado
                                            </CapitalizedRow>
                                        }
                                        checked={watch('blinket_integration')}
                                        type={'checkbox'}
                                        id={`blinket_integration`}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column>Integração Sympla</Form.Label>
                                <Col sm={10}>
                                    <Form.Check
                                        key={'sympla_integration'}
                                        {...register('sympla_integration')}
                                        label={
                                            <CapitalizedRow>
                                                Habilitado
                                            </CapitalizedRow>
                                        }
                                        checked={watch('sympla_integration')}
                                        type={'checkbox'}
                                        id={`sympla_integration`}
                                    />
                                    {watch('sympla_integration')
                                    ? <>
                                        <Form.Group as={Row} className='mb-3'>
                                            <Form.Label column>*ID Sympla</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control {...register('rsvp_sympla_id')} type='text' />
                                                {errors.rsvp_sympla_id?.message && (
                                                    <WarnText> {errors.rsvp_sympla_id.message} </WarnText>
                                                )}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-3'>
                                            <Form.Label column>*Token Sympla</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control {...register('rsvp_sympla_token')} type='text' />
                                                {errors.rsvp_sympla_token?.message && (
                                                    <WarnText> {errors.rsvp_sympla_token.message} </WarnText>
                                                )}
                                            </Col>
                                        </Form.Group>
                                    </>
                                    : <></>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column>Integração Hotmart</Form.Label>
                                <Col sm={10}>
                                    <Form.Check
                                        key={'hotmart_integration'}
                                        {...register('hotmart_integration')}
                                        label={
                                            <CapitalizedRow>
                                                Habilitado
                                            </CapitalizedRow>
                                        }
                                        checked={watch('hotmart_integration')}
                                        type={'checkbox'}
                                        id={`hotmart_integration`}
                                    />
                                    {watch('hotmart_integration')
                                    ? <>
                                        <Form.Group as={Row} className='mb-3'>
                                            <Form.Label column>*ID Hotmart</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control {...register('rsvp_hotmart_id')} type='text' />
                                                {errors.rsvp_hotmart_id?.message && (
                                                    <WarnText> {errors.rsvp_hotmart_id.message} </WarnText>
                                                )}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-3'>
                                            <Form.Label column>*Client ID Hotmart</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control {...register('rsvp_hotmart_client_id')} type='text' />
                                                {errors.rsvp_hotmart_client_id?.message && (
                                                    <WarnText> {errors.rsvp_hotmart_client_id.message} </WarnText>
                                                )}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-3'>
                                            <Form.Label column>*Client Secret Hotmart</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control {...register('rsvp_hotmart_client_secret')} type='text' />
                                                {errors.rsvp_hotmart_client_secret?.message && (
                                                    <WarnText> {errors.rsvp_hotmart_client_secret.message} </WarnText>
                                                )}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-3'>
                                            <Form.Label column>*Basic Auth Hotmart (sem o prefixo Basic)</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control {...register('rsvp_hotmart_basic_auth')} type='text' />
                                                {errors.rsvp_hotmart_basic_auth?.message && (
                                                    <WarnText> {errors.rsvp_hotmart_basic_auth.message} </WarnText>
                                                )}
                                            </Col>
                                        </Form.Group>
                                    </>
                                    : <></>
                                    }
                                </Col>
                            </Form.Group>
                            <AddTicketButton>
                                <Subtitle>Links de Inscrição</Subtitle>
                                {!linkFormOpen &&
                                    <AddButton onClick={() => setLinkFormOpen(true)}>
                                        Adicionar
                                    </AddButton>
                                }
                            </AddTicketButton>
                            {linkFormOpen &&
                                <TicketForm>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Form.Label>Nome</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control {...linkRegister('name')} type='text' />
                                            {linkErrors.name?.message && (
                                                    <WarnText> {linkErrors?.name.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Form.Label>Quantidade de Ingressos</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control {...linkRegister('availability')} type='number' min={0} />
                                            {linkErrors.availability?.message && (
                                                    <WarnText> {linkErrors?.availability.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Form.Label>Imagem da tela inicial (610px x 1190px)</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control {...linkRegister('background_mobile_image')} type='file'/>
                                            {linkErrors.background_mobile_image?.message && (
                                                    <WarnText> {linkErrors?.background_mobile_image.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Form.Label>Imagem do Ingresso (400px x 500px)</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control {...linkRegister('background_image')} type='file'/>
                                            {linkErrors.background_image?.message && (
                                                    <WarnText> {linkErrors?.background_image.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Form.Label>Cor Principal (HEX)</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control {...linkRegister('primary_color')} type='text'/>
                                            {linkErrors.primary_color?.message && (
                                                    <WarnText> {linkErrors?.primary_color.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Form.Label>Template do e-mail de confirmação (ID Brevo)</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control {...linkRegister('email_template')} type='text'/>
                                            {linkErrors.email_template?.message && (
                                                    <WarnText> {linkErrors?.email_template.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Col sm={10}>
                                            <Form.Check
                                                key={'free'}
                                                {...linkRegister('free')}
                                                value={linkWatch('free')?.toString()}
                                                label='Gratuito'
                                            />
                                            {linkErrors.free?.message && (
                                                    <WarnText> {linkErrors?.free.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    {!linkWatch('free') && <>
                                        <Form.Group as={Row} className='mb-3'>
                                            <Form.Label>Preço</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control {...linkRegister('price')} type='number' min={0} />
                                                {linkErrors.price?.message && (
                                                        <WarnText> {linkErrors?.price.message} </WarnText>
                                                )}
        
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-3'>
                                            <Form.Label>Parcelas</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control {...linkRegister('installments')} type='number' min={0} />
                                                {linkErrors.installments?.message && (
                                                        <WarnText> {linkErrors?.installments.message} </WarnText>
                                                )}
        
                                            </Col>
                                        </Form.Group>
                                    </>}
                                    {(linkWatch('free')) &&
                                        <Form.Group as={Row} className='mb-3'>
                                            <Col sm={10}>
                                                <Form.Check
                                                    key={'random_code'}
                                                    {...linkRegister('random_code')}
                                                    value={linkWatch('random_code')?.toString()}
                                                    label='Usar código aleatório como identificação'
                                                />
                                                {linkErrors.random_code?.message && (
                                                        <WarnText> {linkErrors?.random_code.message} </WarnText>
                                                )}
        
                                            </Col>
                                        </Form.Group>
                                    }
                                    <Form.Group as={Row} className='mb-3'>
                                        <Col sm={10}>
                                            <Form.Check
                                                type={'checkbox'}
                                                id={`facialRecognition`}
                                                label={`Reconhecimento Facial`}
                                                {...linkRegister('link_facial_recognition')}
                                            />
                                            {linkErrors.link_facial_recognition?.message && (
                                                <WarnText> {linkErrors.link_facial_recognition.message} </WarnText>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Form.Label>Cancelamento</Form.Label>
                                        <Col sm={10}>
                                            <Form.Select
                                                aria-label='Default select example'
                                                {...linkRegister('cancel_option')}
                                            >
                                                <option value={''}> -- Selecione -- </option>
                                                <option value={'Cancelamento pelo admin'}>Cancelamento pelo admin</option>
                                                <option value={'Cancelamento pelo usuário'}>Cancelamento pelo usuário</option>
                                                
                                            </Form.Select>
                                            {linkErrors.cancel_option?.message && (
                                                    <WarnText> {linkErrors?.cancel_option.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Form.Label>Categoria de Participante</Form.Label>
                                        <Col sm={10}>
                                            <Form.Select
                                                aria-label='Default select example'
                                                {...linkRegister('participant_type')}
                                            >
                                                <option value={''}> -- Selecione -- </option>
                                                {watch('additionalCategories')?.replaceAll(' ','')?.split(',')?.map(c => 
                                                    <option value={c}>{c}</option>
                                                )}
                                                
                                            </Form.Select>
                                            {linkErrors.cancel_option?.message && (
                                                    <WarnText> {linkErrors?.cancel_option.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Form.Label>Informações Adicionais</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control {...linkRegister('additional_info')} type='text'/>
                                            {linkErrors.additional_info?.message && (
                                                    <WarnText> {linkErrors?.additional_info.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Col sm={10}>
                                            <Form.Check
                                                key={'show_terms'}
                                                {...linkRegister('show_terms')}
                                                value={linkWatch('show_terms')?.toString()}
                                                label='Mostrar termos'
                                            />
                                            {linkErrors.show_terms?.message && (
                                                    <WarnText> {linkErrors?.show_terms.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Col sm={10}>
                                            <Form.Check
                                                key={'participant_file'}
                                                {...linkRegister('participant_file')}
                                                value={linkWatch('participant_file')?.toString()}
                                                label='Pedir comprovante'
                                            />
                                            {linkErrors.participant_file?.message && (
                                                    <WarnText> {linkErrors?.participant_file.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-3'>
                                        <Col sm={10}>
                                            <Form.Check
                                                key={'allow_any'}
                                                {...linkRegister('allow_any')}
                                                value={linkWatch('allow_any')?.toString()}
                                                label='Permitir somente pré-inscritos'
                                            />
                                            {linkErrors.cancel_option?.message && (
                                                    <WarnText> {linkErrors?.cancel_option.message} </WarnText>
                                            )}
    
                                        </Col>
                                    </Form.Group>
                                    {linkWatch('allow_any') &&
                                        <Form.Group as={Row} className='mb-3'>
                                            <Col sm={10}>
                                                <Form.Control onChange={(f) => handleImportAllowedParticipants((f.target as HTMLInputElement)?.files[0])} type='file'/>
                                            </Col>
                                        </Form.Group>
                                    }
                                    <AddButton onClick={handleAddLink}>
                                            Adicionar
                                    </AddButton>
                                </TicketForm>
                            }
                            <ListGroup>
                                {links?.map((l) =>
                                    <ListGroup.Item>
                                        <TicketRow>
                                            {l.name}
                                            <Edit onClick={() => setLinkToEdit(l)}/>
                                        </TicketRow>
                                    </ListGroup.Item> 
                                )}
                            </ListGroup>
                            
                        </Form.Group>
                        
                        
                        <DashedHR />
                        <Title id='autoatendimento'>Autoatendimento</Title>
                        <Form.Group as={Row} className='mb-1 d-flex justify-content-center'>
                            <Form.Label column sm={2}>
                                Cor do botão de check-in
                            </Form.Label>
                            <Col sm={10} className='align-self-center'>
                                <Form.Control type='text' {...register('buttonColor')} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-1 d-flex justify-content-center'>
                            <Form.Label column sm={2}>
                                Mensagem de boas-vindas
                            </Form.Label>
                            <Col sm={10} className='align-self-center'>
                                <Form.Control type='text' {...register('welcomeMessage')} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-1 d-flex justify-content-center'>
                            <Form.Label column sm={2}>
                                *Imagem fundo AutoAtendimento (1920px x 1080px)
                            </Form.Label>
                            <Col sm={10} className='align-self-center'>
                                <Form.Control type='file' {...register('backgroundImage')} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                Imagem Atual:
                            </Form.Label>
                            {imgPreview ? (
                                <ImagePreview src={imgPreview} />
                            ) : (
                                <Col sm={10} className='align-self-center font-weight-bold'>
                                    <Bolder> Nenhuma </Bolder>
                                </Col>
                            )}
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Check
                                    type={'checkbox'}
                                    id={`canRegisterParticipant`}
                                    label={`Permitir cadastro durante auto-atendimento`}
                                    {...register('canRegisterParticipant')}
                                />
                                {errors.canRegisterParticipant?.message && (
                                    <WarnText> {errors.canRegisterParticipant.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Check
                                    type={'checkbox'}
                                    id={`removeTitle`}
                                    label={`Mostrar título no auto-atendimento`}
                                    {...register('removeTitle')}
                                />
                                {errors.removeTitle?.message && (
                                    <WarnText> {errors.removeTitle.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Check
                                    type={'checkbox'}
                                    id={`checkinType`}
                                    label={`Pedir confirmação no check-in`}
                                    {...register('checkinType')}
                                />
                                {errors.checkinType?.message && (
                                    <WarnText> {errors.checkinType.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Check
                                    type={'checkbox'}
                                    id={`useVirtualKeyboard`}
                                    label={`Usar teclado virtual`}
                                    {...register('useVirtualKeyboard')}
                                />
                                {errors.useVirtualKeyboard?.message && (
                                    <WarnText> {errors.useVirtualKeyboard.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Check
                                    type={'checkbox'}
                                    id={`showCheckinButton`}
                                    label={`Mostrar botão de check-in no autoatendimento`}
                                    {...register('showCheckinButton')}
                                    defaultChecked
                                />
                                {errors.showCheckinButton?.message && (
                                    <WarnText> {errors.showCheckinButton.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>


                        <DashedHR />
                        {isUserAdmin && <>
                            <Title id='módulos'>Módulos</Title>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column>CEK</Form.Label>
                                <Col sm={10}>
                                    <Form.Check
                                        key={'cek'}
                                        {...register('cek')}
                                        label={
                                            <CapitalizedRow>
                                                Habilitado
                                            </CapitalizedRow>
                                        }
                                        value={''}
                                        type={'checkbox'}
                                        id={`cek`}
                                    />
                                    {watch('cek')
                                    ? <Form.Group as={Row} className='mb-3'>
                                        <Form.Label column>*Quantidade</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control {...register('cekQuantity')} type='number' />
                                            {errors.cekQuantity?.message && (
                                                <WarnText> {errors.cekQuantity.message} </WarnText>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    : <></>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column>Malex</Form.Label>
                                <Col sm={10}>
                                    <Form.Check
                                        key={'malex'}
                                        {...register('malex')}
                                        label={
                                            <CapitalizedRow>
                                                Habilitado
                                            </CapitalizedRow>
                                        }
                                        value={''}
                                        type={'checkbox'}
                                        id={`malex`}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column>Capture Control</Form.Label>
                                <Col sm={10}>
                                    <Form.Check
                                        key={'attraction'}
                                        {...register('attraction')}
                                        label={
                                            <CapitalizedRow>
                                                Habilitado
                                            </CapitalizedRow>
                                        }
                                        value={''}
                                        type={'checkbox'}
                                        id={`attraction`}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column>Enquetes</Form.Label>
                                <Col sm={10}>
                                    <Form.Check
                                        key={'enquetes'}
                                        {...register('enquetes')}
                                        label={
                                            <CapitalizedRow>
                                                Habilitado
                                            </CapitalizedRow>
                                        }
                                        value={''}
                                        type={'checkbox'}
                                        id={`cek`}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column>Controle de Acesso</Form.Label>
                                <Col sm={10}>
                                    <Form.Check
                                        key={'accessControl'}
                                        {...register('accessControl')}
                                        label={
                                            <CapitalizedRow>
                                                Habilitado
                                            </CapitalizedRow>
                                        }
                                        value={''}
                                        type={'checkbox'}
                                        id={`accessControl`}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column>eGuide</Form.Label>
                                <Col sm={10}>
                                    <Form.Check
                                        key={'eGuide'}
                                        {...register('eGuide')}
                                        label={
                                            <CapitalizedRow>
                                                Habilitado
                                            </CapitalizedRow>
                                        }
                                        value={''}
                                        type={'checkbox'}
                                        id={`eGuide`}
                                    />
                                    {watch('eGuide')
                                    ? <Form.Group as={Row} className='mb-3'>
                                        <Form.Label column>*URL</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control {...register('qrCodeUrl')} type='text' />
                                            {errors.qrCodeUrl?.message && (
                                                <WarnText> {errors.qrCodeUrl.message} </WarnText>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    : <></>
                                    }
                                </Col>
                            </Form.Group>
                            <DashedHR />
                        </>}

                        <Form.Group as={Row} className='mb-3'>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Check
                                    type={'checkbox'}
                                    id={`Inativo-${'checkbox'}`}
                                    label={`Inativo`}
                                    {...register('active')}
                                />
                            </Col>
                        </Form.Group>
                        <SubmitButton>
                            <TButton type='submit'> Gravar </TButton>
                        </SubmitButton>
                    </Form>
                </EventAddForm>
                {/*isUserAdmin &&
                    <DeleteButton>
                        <TButton type='button' onClick={handleDeleteEvent}> Excluir </TButton>
                    </DeleteButton>
                */}
            </FormContainer>
            </RowContainer>
        </EventBackground>
    );
}
