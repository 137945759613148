export const Etiquetas: IEtiquetas[] = [
    {
        id: 'label1',
        imagePath: '/assets/images/labels/label1.png',
        fields: 'Nome',
        sizes: {
            '10x5': '^XA^FXlabel1:10x5~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^MMT^PW799^LL400^LS0^FT0,216^A@N,79,79,ARI001.FNT^FB799,1,20,C^FH^CI28^FD[[NOME_1]]^FS^CI27^XZ',
            '7x3': '^XA^FXlabel1:7x3~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW559^LL240^LS0^FT1,137^A@N,48,48,ARI001.FNT^FB558,1,12,C^FH^CI28^FD[[NOME_1]]^FS^CI27^PQ1,0,1,Y^XZ',
        },
    },
    {
        id: 'label2',
        imagePath: '/assets/images/labels/label2.png',
        fields: 'Nome, Empresa, QR Code',
        sizes: {
            '10x5': '^XA^FXlabel2:10x5~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^MMT^PW799^LL400^LS0^FT25,90^A@N,79,79,ARI001.FNT^FH^CI28^FD[[NOME_1]]^FS^CI27^FT25,179^A@N,79,79,ARI000.FNT^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT608,407^BQN,2,8^FH^FDLA,[[QRCODE]]^FS^XZ',
            '7x3': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW559^LL240^LS0^FT10,51^A@N,48,48,ARI001.FNT^CI28^FD[[NOME_1]]^FS^CI27^FT10,110^A@N,36,36,ARI000.FNT^CI28^FD[[EMPRESA]]^FS^CI27^FT428,244^BQN,2,4^FDLA,[[QRCODE]]^FS^PQ1,,,Y^XZ',
            '9x4': '^XA^FS~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD10^JUS^LRN^CI27^PA0,1,1,0^MMT^PW719^LL320^LS0^FT25,76^A@N,62,61,ARI001.FNT^FH^CI28^FD[[NOME_1]]^FS^CI27^FT25,141^A@N,45,46,ARI000.FNT^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT550,324^BQN,2,7^FH^FDLA,[[QRCODE]]^FS^XZ'
        },
    },
    {
        id: 'label3',
        imagePath: '/assets/images/labels/label3.png',
        fields: 'Nome, Empresa, Código de Barras',
        sizes: {
            '10x5': '^XA^FXlabel3:10x5~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^MMT^PW799^LL400^LS0^FT14,90^A@N,79,79,ARI001.FNT^FH^CI28^FD[[NOME_1]]^FS^CI27^FT14,179^A@N,79,79,ARI000.FNT^FH^CI28^FD[[EMPRESA]]^FS^CI27^BY2,3,93^FT482,350^BCN,,Y,N^FH^FD>:[[QRCODE]]^FS^XZ',
            '7x3': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW559^LL240^LS0^FT10,51^A@N,48,48,ARI001.FNT^FH^CI28^FD[[NOME_1]]^FS^CI27^FT10,96^A@N,36,36,ARI000.FNT^FH^CI28^FD[[EMPRESA]]^FS^CI27^BY1,3,51^FT392,219^BCN,,Y,N^FH^FD>:[[BARCODE]]^FS^PQ1,,,Y^XZ',
        },
    },
    {
        id: 'label4',
        imagePath: '/assets/images/labels/label4.png',
        fields: 'Nome, Empresa, QR Code',
        sizes: {
            '10x5': '^XA^FXlabel4:10x5~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^MMT^PW799^LL400^LS0^FT1,90^A@N,79,79,ARI001.FNT^FB798,1,20,C^FH^CI28^FD[[NOME_1]]^FS^CI27^FT1,179^A@N,79,79,ARI000.FNT^FB798,1,20,C^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT316,408^BQN,2,8^FH^FDLA,[[QRCODE]]^FS^XZ',
            '7x3': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW559^LL240^LS0^FT1,51^A@N,48,48,ARI001.FNT^FB558,1,8,C^FH^CI28^FD[[NOME_1]]^FS^CI27^FT0,96^A@N,36,36,ARI000.FNT^FB559,1,7,C^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT238,240^BQN,2,4^FH^FDLA,[[QRCODE]]^FS^PQ1,,,Y^XZ',
        },
    },
    {
        id: 'label6',
        imagePath: '/assets/images/labels/label6.png',
        fields: 'Nome, Empresa',
        sizes: {
            '10x5': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW639^LL320^LS0^FT1,183^A@N,39,38,ARI000.FNT^FB638,1,10,C^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT1,78^A@N,39,38,ARI001.FNT^FB638,1,10,C^FH^CI28^FD[[NOME_1]]^FS^CI27^PQ1,,,Y^XZ',
            '7x3': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW639^LL320^LS0^FT1,183^A@N,39,38,ARI000.FNT^FB638,1,10,C^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT1,78^A@N,39,38,ARI001.FNT^FB638,1,10,C^FH^CI28^FD[[NOME_1]]^FS^CI27^PQ1,,,Y^XZ',
        },
    },
    {
        id: 'label9',
        imagePath: '/assets/images/labels/label9.png',
        fields: 'Nome, Empresa, Código de Barras, QR Code',
        sizes: {
            '7x3': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW559^LL240^LS0^FT1,51^A@N,48,48,ARI001.FNT^FB558,1,8,C^FH^CI28^FD[[NOME_1]]^FS^CI27^FT0,96^A@N,36,36,ARI000.FNT^FB559,1,7,C^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT430,246^BQN,2,4^FH^FDLA,[[QRCODE]]^FS^BY1,3,62^FT11,221^BCN,,Y,N^FH^FD>:[[BARCODE]]^FS^PQ1,,,Y^XZ',
            '9x5': '^XA^FXlabel9:9x5~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^MMT^PW719^LL400^LS0^FT0,87^A@N,62,61,ARI001.FNT^FB718,1,16,C^FH^CI28^FD[[NOME_1]]^FS^CI27^FT0,171^A@N,62,61,ARI000.FNT^FB718,1,16,C^FH^CI28^FD[[EMPRESA]]^FS^CI27^BY1,3,95^FT28,349^BCN,,Y,N^FH^FD>:[[QRCODE]]^FS^FT572,374^BQN,2,5^FH^FDLA,[[QRCODE]]^FS^XZ',
        },
    },
    {
        id: 'label10',
        imagePath: '/assets/images/labels/label10.png',
        fields: 'Nome, Categoria, Estado',
        sizes: {
            '9x4': '^XA^FXlabel10:9x4~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW639^LL320^LS0^FT1,115^A@N,50,50,ARI001.FNT^FB638,1,14,C^FH^CI28^FD[[NOME_1]]^FS^CI27^FT1,198^A@N,39,38,ARI000.FNT^FB638,1,10,C^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT1,264^A@N,39,38,ARI000.FNT^FB638,1,10,C^FH^CI28^FD[[ESTADO]]^FS^CI27^PQ1,,,Y^XZ',
        },
    },
    {
        id: 'label11',
        imagePath: '/assets/images/labels/label11.png',
        fields: 'Nome, Empresa, QR Code',
        sizes: {
            '27x2,5': '^XA^FS~TA000~JSN^LT0^MNM,0^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD10^JUS^LRN^CI27^PA0,1,1,0^MMT^PW203^LL2198^LS0^FO20,1400[[IMAGE_ZPL]]^FT57,1190^A@B,39,38,ARI001.FNT^FH^CI28^FD[[NOME_1]]^FS^CI27^FT145,1190^A@B,39,38,ARI001.FNT^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT15,1400^BQN,2,8^FH^FDLA,[[QRCODE]]^FS^XZ',
        },
    },
    {
        id: 'label12',
        imagePath: '/assets/images/labels/label12.png',
        fields: 'Nome, Empresa, Cargo',
        sizes: {
            '7x3': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW639^LL320^LS0^FT1,183^A@N,39,38,ARI000.FNT^FB638,1,10,C^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT1,78^A@N,39,38,ARI001.FNT^FB638,1,10,C^FH^CI28^FD[[NOME_1]]^FS^CI27^FT1,246^A@N,39,38,ARI000.FNT^FB638,1,10,C^FH^CI28^FD[[CARGO]]^FS^CI27^PQ1,,,Y^XZ',
            '8x4': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW639^LL320^LS0^FT0,108^A@N,51,51,ARI001.FNT^FB636,1,13,C^FH^CI28^FD[[NOME_1]]^FS^CI27^FT0,165^A@N,37,38,ARI000.FNT^FB636,1,9,C^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT0,236^A@N,37,38,ARI000.FNT^FB636,1,9,C^FH^CI28^FD[[CARGO]]^FS^CI27^PQ1,,,Y^XZ'
        },
    },
    {
        id: 'label13',
        imagePath: '/assets/images/labels/label13.png',
        fields: 'Nome, Empresa, Categoria, QR Code',
        sizes: {
            '8x4': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^MMT^PW639^LL320^LS0^FT8,70^A@N,51,51,ARI001.FNT^FH^CI28^FD[[NOME_1]]^FS^CI27^FT8,129^A@N,39,38,ARI000.FNT^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT8,200^A@N,39,38,ARI000.FNT^FH^CI28^FD[[CATEGORIA]]^FS^CI27^FT452,331^BQN,2,6^FH,0,0^FDLA,[[QRCODE]]^FS^XZ',
        },
    },
    {
        id: 'label14',
        imagePath: '/assets/images/labels/label14.png',
        fields: 'Nome, Empresa, Cargo, QR Code',
        sizes: {
            '8x4': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^MMT^PW639^LL320^LS0^FT8,70^A@N,51,51,ARI001.FNT^FH^CI28^FD[[NOME_1]]^FS^CI27^FT8,129^A@N,39,38,ARI000.FNT^FH^CI28^FD[[EMPRESA]]^FS^CI27^FT8,200^A@N,39,38,ARI000.FNT^FH^CI28^FD[[CARGO]]^FS^CI27^FT452,331^BQN,2,6^FH,0,0^FDLA,[[QRCODE]]^FS^XZ',
        }
    },
    {
        id: 'label15',
        imagePath: '/assets/images/labels/label15.png',
        fields: 'Nome, Categoria',
        sizes: {
            '8x4': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW639^LL320^LS0^FT0,136^A@N,51,51,ARI001.FNT^FB638,1,13,C^FH^CI28^FD[[NOME_1]]^FS^CI27^FT1,208^A@N,37,38,ARI000.FNT^FB638,1,9,C^FH^CI28^FD[[CATEGORIA]]^FS^CI27^PQ1,,,Y^XZ',
        }
    }


];

export const ProtocoloMalex:IEtiquetas = {
    id: 'protocoloMalex',
    imagePath: '',
    fields: '',
    sizes: {
        '10x6': '^XA^FS~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR8,8~SD15^JUS^LRN^CI27^PA0,1,1,0^MMT^PW799^LL480^LS0^FT46,182^A@N,37,38,ARI001.FNT^FH^CI28^FD[[NOME_1]]^FS^CI27^FT47,237^A@N,37,38,ARI001.FNT^FH^CI28^FD[[DATA]]^FS[[ITENS]]^CI27^FT0,59^A@N,28,28,ARI001.FNT^FB798,1,7,C^FH^CI28^FD[[EVENTO]] - protocolo malex^FS^CI27^FT584,473^BQN,2,7^FH^FDLA,[[QRCODE]]^FS^FT0,114^A@N,25,25,ARI001.FNT^FB798,1,6,C^FH^CI28^FDResponsável: [[USUARIO]]^FS^CI27^XZ'
    }
}

export const AppConfigLabel:IEtiquetas = {
    id: 'appConfigLabel',
    imagePath: '',
    fields: '',
    sizes: {
        '7x3': '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD15^JUS^LRN^CI27^PA0,1,1,0^XZ^XA^MMT^PW639^LL320^LS0^FT17,53^A0N,31,30^FH^CI28^FD[[CONFIG]]^FS^CI27^FT275,285^BQN,2,4^FH^FDLA,[[QRCODE]]^FS^PQ1,,,Y^XZ'
    }
}



export interface IEtiquetas {
    id: string;
    imagePath: string;
    fields: string;
    sizes: {
        '7x3'?: string;
        '8x4'?: string;
        '10x5'?: string;
        '9x5'?: string;
        '9x4'?: string;
        '27x2,5'?: string;
        '10x6'?: string;
    };
}
